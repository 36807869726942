import kaus from './homepage_kaus.png';
import mkdin from './homepage_mkidn.png';
import nid from './homepage_nid.png';
import us from './homepage_us.png';
import geoportal from './splashButton_geoportal.png';
import makiety from './splashButton_makiety.png';
import modele3d from './splashButton_modele3d.png';
import zwiedzanie from './splashButton_zwiedzanie.png';

export default {
  kaus,
  mkdin,
  nid,
  us,
  splash: { geoportal, makiety, modele3d, zwiedzanie },
};
