import { Markdown } from 'components';
import { withTranslate } from 'helpers';
import { React, compose, PropTypes } from 'utils/create';
import Mailto from 'react-protected-mailto';

const Contact = ({ _ }) => (
  <section>
    <Markdown source={_('contact.content')} />
    <Mailto email={_('contact.email')} />
  </section>
);

Contact.propTypes = {
  _: PropTypes.func.isRequired,
};

export default compose(withTranslate)(Contact);
