// import { api } from 'utils';
import * as types from './types';

const getMock = (type, path) =>
  import(`./mocks/${type}/${path}.json`).then(response => response.default);

const getMockList = type => import(`./mocks/${type}.json`).then(response => response.default);

export const fetchContentList = type => ({
  type: types.CONTENT_LIST,
  payload: getMockList(type),
  meta: { type },
  // payload: api.get(`/content/${type}`),
});

export const fetchContent = (type, id) => ({
  type: types.CONTENT_GET,
  payload: getMock(type, id),
  meta: { type, id },
  // payloadX: api.get('/pages', { id }),
});
