import ilustracja1 from './Ilustracja_1.jpg';
import ilustracja2 from './Ilustracja_2.jpg';
import ilustracja3 from './Ilustracja_3.jpg';
import ilustracja4 from './Ilustracja_4.jpg';
import ilustracja5 from './Ilustracja_5.jpg';

export default {
  ilustracja1,
  ilustracja2,
  ilustracja3,
  ilustracja4,
  ilustracja5,
};
