import { PropTypes, React } from 'utils/create';

const Logo = ({ className }) => (
  <svg className={className} viewBox="0 0 944.88135 944.88269">
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,944.88267)">
      <g>
        <g clipPath="url(#clipPath3783)">
          <g transform="translate(244.1167,282.708)">
            <path d="m 0,0 -45.668,-115.557 c 19.561,-7.748 40.859,-12.039 63.177,-12.039 22.289,0 43.565,4.28 63.102,12.01 L 35.04,0.01 c 17.689,6.992 30.224,24.209 30.224,44.386 h 125.135 c 0,16.131 -2.197,31.887 -6.472,47.106 h -47.072 l 34.628,32.469 32.08,30.078 190.116,178.26 h -210.67 l -33.386,-47.11 h 124.703 l -76.229,-71.476 -53.993,-50.629 -0.003,0.002 -91.793,-86.069 c -8.712,9.286 -21.061,15.121 -34.799,15.121 -13.763,0 -26.131,-5.857 -34.844,-15.171 l -90.745,84.851 c -28.772,-30.73 -46.4,-72.01 -46.4,-117.432 H -30.244 C -30.244,24.209 -17.701,6.988 0,0" />
          </g>
          <g transform="translate(298.3911,554.2217)">
            <path d="m 0,0 c -9.97,-14.066 -21.393,-30.111 -26.746,-37.779 l 15.733,-3.817 18.042,-4.34 c 31.823,-7.66 59.993,-23.14 82.723,-44.103 l 34.459,32.31 c -21.061,20 -45.985,36.19 -73.851,47.319 11.513,16.248 24.121,34.031 24.121,34.031 l 26.342,37.174 H -89.621 L -123.006,13.689 H 9.7 Z" />
          </g>
          <g transform="translate(431.7974,403.415)">
            <path d="m 0,0 c 10.446,-23.404 16.178,-49.271 16.178,-76.311 0,-102.755 -83.598,-186.353 -186.35,-186.353 -66.738,0 -128.435,36.49 -161.381,93.174 l -29.379,-41.453 c 15.501,-21.952 34.753,-41.188 57.187,-56.86 39.292,-27.455 85.479,-41.967 133.573,-41.967 128.728,0 233.455,104.729 233.455,233.459 0,39.532 -9.791,77.061 -27.327,110.024 z" />
          </g>
          <g transform="translate(504.0112,374.21)">
            <path d="M 0,0 C 2.983,-15.48 4.527,-31.264 4.527,-47.105 H 109.693 L 76.308,0 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
