import { Gallery, Page } from 'components';
import { React } from 'utils/create';
import assets from './assets';

const RemoteSensingPage = () => (
  <Page>
    <h1>Teledetekcja</h1>
    <h2>Teledetekcja archeologiczna</h2>
    <p>
      Metody wykorzystywane podczas badań archeologicznych można podzielić na dwie podstawowe grupy.
      Zaliczamy do nich metody inwazyjne (nazywane także destruktywnymi) i metody nieinwazyjne. Do
      grupy metod inwazyjnych należą m.in. wykopaliska, sondaże i wiercenia, a ich nazwa bierze się
      stąd, że niszczą one bezpowrotnie badane obiekty.
    </p>
    <p>
      Z kolei do grupy metod nieinwazyjnych zalicza się np. fotografia lotnicza, zobrazowania
      satelitarne, różne typy skanowania laserowego oraz metody geofizyczne (np. georadarowa,
      magnetyczna, elektrooporowa). Określa się je także mianem metod teledetekcyjnych, ponieważ
      pozwalają one badać obiekty i fenomeny na odległość. Czasami, choć nie zawsze, także metody
      geofizyczne zalicza się do tej grupy.
    </p>
    <p>
      W badaniach archeologicznych radzieckich magazynów broni atomowej są wykorzystywane wyłącznie
      metody nieinwazyjne i źródła teledetekcyjne. Uzyskane dzięki nim dane są porównywane i
      analizowane w systemach informacji geograficznej (GIS).
    </p>

    <h2>Archiwalne i współczesne fotografie lotnicze</h2>
    <p>
      Do połowy lat 90. XX wieku wszelkie wykonane w Polsce fotografie lotnicze, zanim zostały
      przekazane służbom cywilnym do celów kartograficznych, były poddawane cenzurze. Jeżeli na
      zdjęciu znajdował się obiekt wojskowy, zakład produkcyjny lub np. węzeł kolejowy o znaczeniu
      strategicznym, skrupulatnie wstawiano w tym miejscu białą plamę. Z tego powodu zdjęcia
      lotnicze przechowywane w krajowych archiwach bardzo rzadko są przydatne w studiach nad
      zimnowojennymi obiektami wojskowymi.
    </p>
    <p>
      O tym, jak wyglądały radzieckie bazy atomowe nie dowiemy się także z akt projektu
      &quot;Wisła&quot;, znajdujących się w archiwach Instytutu Pamięci Narodowej. Po ukończeniu ich
      budowy w 1969 roku wydano bowiem instrukcję, na mocy której część dokumentacji i planów
      geodezyjnych zniszczono, a resztę przekazano stronie radzieckiej. We wspomnianych aktach są
      zatem informacje o tym, ile kosztowała budowa, jak wiele miejsc parkingowych było w garażach i
      ile osób mogła pomieścić koszarowa stołówka oraz kino. Nie poznamy natomiast planu
      przestrzennego tych obiektów, rozmieszczenia budynków i ogrodzeń. Sytuację komplikuje
      dodatkowo fakt, że z czasem sami Rosjanie zaczęli powiększać bazę, wznosząc w jej obrębie nowe
      budynki, po których nie zachowała się żadna dokumentacja.
    </p>
    <p>
      Najstarsze nieocenzurowane zdjęcia lotnicze dostępne dziś w archiwach Głównego Urzędu Geodezji
      i Kartografii (GUGiK) pochodzą z 1996 roku. Zawierają one wiele wartościowych informacji o
      planach przestrzennych, których nie odnajdziemy nigdzie indziej. Należy mieć jednak na uwadze,
      że utrwalone obiekty były już od kilku lat opuszczone, pozbawione ochrony i systematycznie
      niszczone.
    </p>

    <Gallery
      images={assets.filter(asset => asset.tags.includes('teledetekcja fotografie lotnicze'))}
    />

    <h2>Szpiedzy z kosmosu. Odtajnione zobrazowania satelitarne z misji CORONA i HEXAGON</h2>
    <p>
      18 sierpnia 1960 roku rozpoczęła się pierwsza udana satelitarna misja szpiegowska programu o
      kryptonimie CORONA, którą zorganizowano na zamówienie służb wywiadowczych Stanów
      Zjednoczonych. Powracający na Ziemię satelita dostarczył 1432 klatki z fotografiami
      pokrywającymi 4 mln km 2 terytorium Związku Radzieckiego i innych krajów bloku wschodniego.
      Było to znacznie więcej niż udało się zdobyć podczas wszystkich dotychczasowych 24 lotów
      samolotu Lockheed U-2.
    </p>
    <p>
      Program CORONa był kontynuowany do 1972 roku, a satelity szpiegowskie wielokrotnie
      fotografowały terytorium Polski i obecne na nim radzieckie bazy. Rozdzielczość terenowa
      uzyskiwanych zobrazowań ulegała ciągłej poprawie. Podczas pierwszej misji wynosiła ona 12 m, a
      w kolejnych wahała się pomiędzy 8 m a 1,8 m. W rezultacie zobrazowania satelitarne pozwoliły
      na uzyskanie na niespotykaną dotychczas skalę bardzo szczegółowych informacji wywiadowczych.
    </p>
    <p>
      Pierwsze wyraźne fotografie dokumentujące radzieckie bazy atomowe w Polsce, zostały wykonane
      jeszcze w trackie ich budowy 28 lipca 1969 roku. Widać na nich wyraźnie, że we wszystkich
      trzech lokalizacjach sfinalizowano już prace nad najważniejszymi obiektami (schronami T-7,
      garażami, drogami i budynkami koszarowymi). Nie oznacza to jednak wcale, że interpretatorzy
      CIA potrafili prawidłowo zidentyfikować te obiekty. Wiele wskazuje na to, że pomimo iż
      posiadali oni w swoich zasobach dobrej jakości zobrazowania satelitarne to udało im się tego
      dokonać dopiero w następnej dekadzie.
    </p>
    <p>
      Po zakończeniu programu CORONa terytorium Polski było fotografowane między innymi przez
      satelity programu HEXAGON w latach 1972-1980. Szpiegowskie zobrazowania satelitarne są od 1995
      roku stopniowo odtajniane i przekazywane do Archiwum Narodowego USA w Waszyngtonie (National
      Archives and Records Administration). Są one przechowywane w kolekcji odtajnionych zobrazowań
      satelitarnych (declassified intelligence satellite photographs (DISP)).
    </p>

    <Gallery images={assets.filter(asset => asset.tags.includes('corona'))} />

    <h2>Lotnicze skanowanie laserowe</h2>
    <p>
      Lotnicze skanowanie laserowe (ang. ALS – Airborne Laser Scanning) jest narzędziem, z którego
      archeolodzy korzystają od niespełna dwóch dekad. W literaturze fachowej na jego określenie
      stosuje się także termin lotniczy LiDAR. Jest to akronim angielskich słów light detection and
      ranging, czyli wykrywanie i określanie położenia za pomocą światła. Metoda ta pozwala na
      uzyskanie precyzyjnych pomiarów powierzchni ziemi i znajdujących się na niej obiektów (tzw.
      chmury punktów).
    </p>
    <p>
      Dla potrzeb badań archeologicznych, za pomocą aplikacji komputerowych, dokonuje się filtracji
      zebranych pomiarów w taki sposób, by odrzucić wszystkie punkty pomiarowe obiektów naziemnych
      oraz roślinności i pozostawić jedynie te, które odzwierciedlają powierzchnię ziemi. Można je
      następnie przekształcić w numeryczny model terenu i analizować za pomocą pochodnych obrazów.
    </p>
    <p>
      Zaawansowanie techniczne tej metody jest jednak pośrednio także jej głównym ograniczeniem.
      Pojawiała się ona bowiem stosunkowo niedawno. Najwcześniejsze dostępne w państwowych archiwach
      dane ze skanowania laserowego na terenie Polski, zostały wykonane po 2010 roku, a więc niemal
      dwie dekady po wycofaniu się wojsk rosyjskich z Polski. W tym czasie wiele budynków było już
      zburzonych, a teren uległ znaczącym przekształceniom. Duże obszary poddano orce leśnej i
      posadzono na nich drzewa. Prace te częściowo wymazały z powierzchni ziemi ślady po obiektach
      związane z obecnością wojskową. Nie zatarły jednak wszystkiego, dzięki czemu lotnicze
      skanowanie laserowe dostarczyło wielu informacji na temat systemu umocnień polowych i schronów
      otaczających bazy.
    </p>

    <Gallery images={assets.filter(asset => asset.tags.includes('laserowe'))} />

    <h2>Systemy informacji geograficznej (GIS)</h2>
    <p>
      Systemy Informacji Geograficznej (ang. Geographic Information System) to narzędzia komputerowe
      umożliwiające przechowywanie, przetwarzanie i prezentowanie danych przestrzennych. Przy
      wykorzystaniu systemów GIS można tworzyć i przeglądać mapy, wykonywać analizy, modelować
      różnego rodzaju procesy oraz archiwizować dokumentację pochodzącą z badań archeologicznych. W
      archeologii systemy GIS zaczęto stosować już od lat 70. XX wieku, ale stały się one popularne
      dopiero w latach 90. XX wieku, wraz z upowszechnieniem się komputerów osobistych o
      satysfakcjonującej mocy obliczeniowej.
    </p>
    <p>
      We współczesnym świecie prawie każda osoba korzystająca z internetu ma styczność z Systemami
      Informacji Geograficznej. Na takiej zasadzie działają wszelkiego rodzaju interaktywne mapy i
      geoportale udostępniające w formie warstw mapy topograficzne, ortofotomapy czy numeryczne
      modele terenu. Niezwykle popularne OpenStreetMap i Google Earth także są narzędziami
      spokrewnionymi z GIS. Poza warstwami odwzorowującymi powierzchnię ziemi, oferują one możliwość
      przeglądania zgeoreferowanych zdjęć wykonanych przez użytkowników, wczytywanie autorskich map
      tematycznych oraz dostęp do informacji opisowych. Co prawda aplikacje te nie pozwalają na
      wykonywanie złożonych analiz, ze względu jednak na prostotę obsługi oraz bogactwo danych,
      które można błyskawicznie przeglądać, archeolodzy korzystają z nich w swojej pracy bardzo
      często.
    </p>
    <p>
      Mimo iż systemy informacji geograficznej były pierwotnie rozwijane do zastosowań
      geograficznych, posiadają one kilka unikatowych cech, które czynią je przydatnymi w badaniach
      archeologicznych. Ponieważ większość ze zgromadzonych w ramach projektu danych dotyczących
      radzieckich baz atomowych ma charakter przestrzenny, są one przetwarzane i przechowywane za
      pomocą systemów GIS. Podczas realizacji projektu wykorzystywane są aplikacje działające na
      zasadzie otwartych licencji, a główna baza danych została wykonana w programie QGIS.
    </p>

    <Gallery images={assets.filter(asset => asset.tags.includes('gis'))} />
  </Page>
);

export default RemoteSensingPage;
