/* eslint-disable */
import { ClassNames } from '@emotion/core';
import { Button, Col, Drawer, Icon, Layout, Menu, Row, Tooltip } from 'antd';
import { ContentWrapper, Link, Logo, Responsive } from 'components';
import config from 'config';
import { withTranslate } from 'helpers';
import { withRouter } from 'react-router-dom';
import { media } from 'utils';
import { Component, compose, PropTypes, React, styled } from 'utils/create';
import { colors } from 'utils/variables';

const drawerStyle = {
  '& .ant-drawer-body': {
    margin: 0,
    padding: 0,
  },
  '& .ant-drawer-header': {
    height: config.ui.headerHeightPx,
  },
  '& .ant-drawer-title': {
    padding: '5px 0',
  },
  '& .ant-drawer-close': {
    padding: '4px',
  },
};

const toRight = transparent => ({
  float: 'right',
  ...(transparent
    ? {
        textShadow: `0 0 10px ${colors.black}`,
      }
    : {}),

  '& li': {
    marginBottom: 0,
  },
});

const CenteredCol = styled(Col)({
  textAlign: 'center',
});

const Hamburger = styled(({ visible, transparent, ...props }) => (
  <Button ghost icon={visible ? 'menu-fold' : 'menu-unfold'} {...props} />
))(({ transparent }) => ({
  border: 'none',
  padding: 0,
  width: '32px',
  fontSize: '1.5rem',
  ...(transparent
    ? {
        color: `${colors.white} !important`,
        borderColor: `${colors.white} !important`,
      }
    : {
        color: `${colors.black} !important`,
        borderColor: `${colors.black} !important`,
      }),
}));

const StyledLogo = styled(Logo)(({ transparent }) => ({
  height: '3rem',
  margin: '0 auto',
  display: 'block',
  cursor: 'pointer',
  fill: transparent ? 'transparent' : colors.black,
}));

const MobileLogo = styled(Logo)({
  height: '10rem',
  margin: '1rem auto 0',
  display: 'block',
  fill: colors.black,
});

const HeaderWrapper = styled(({ transparent, children, ...props }) => (
  <Layout.Header {...props}>{children}</Layout.Header>
))(({ transparent }) => ({
  padding: 0,
  position: 'fixed',
  zIndex: 100,
  width: '100%',
  height: config.ui.headerHeightPx,
  transition: 'all 200ms',

  ...(transparent
    ? {
        color: colors.white,
        backgroundColor: 'transparent',
        padding: '1rem 0',
      }
    : {
        color: colors.black,
        backgroundColor: colors.background,
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, .25)',
      }),
}));

class Header extends Component {
  state = {
    drawerVisible: false,
  };

  drawMenu = params => {
    const {
      _,
      location: { pathname },
      routes,
    } = this.props;

    return (
      <Menu {...params} selectedKeys={[pathname]} onClick={this.toggleDrawer}>
        {routes
          .toJS()
          .filter(route => !route.hidden)
          .map(({ path, href, key, icon, iconTheme, tooltip }) => (
            <Menu.Item key={key}>
              {tooltip ? (
                <Tooltip title={_(`menu.${key}`)} placement="bottom">
                  <Link to={href || path}>{icon && <Icon type={icon} theme={iconTheme} />}</Link>
                </Tooltip>
              ) : (
                <Link to={href || path}>
                  {icon && <Icon type={icon} theme={iconTheme} />}
                  {_(`menu.${key}`)}
                </Link>
              )}
            </Menu.Item>
          ))}
      </Menu>
    );
  };

  toggleDrawer = () => {
    const { drawerVisible } = this.state;
    this.setState({ drawerVisible: !drawerVisible });
  };

  render() {
    const { history, transparentHeader } = this.props;
    const { drawerVisible } = this.state;

    return (
      <HeaderWrapper transparent={transparentHeader}>
        <ClassNames>
          {({ css }) => (
            <ContentWrapper>
              <Row type="flex" align="middle">
                <Col xs={0} md={4} onClick={() => history.push('/')}>
                  <StyledLogo transparent={transparentHeader} />
                </Col>
                <Col xs={0} md={20}>
                  {this.drawMenu({
                    mode: 'horizontal',
                    theme: transparentHeader ? 'dark' : 'light',
                    className: css(toRight(transparentHeader)),
                  })}
                </Col>
                <CenteredCol xs={{ span: 4, push: 20 }} md={0}>
                  <Hamburger
                    onClick={this.toggleDrawer}
                    visible={drawerVisible}
                    transparent={transparentHeader}
                  />
                </CenteredCol>
              </Row>

              <Responsive maxWidth={media.md}>
                <Drawer
                  placement="left"
                  onClose={this.toggleDrawer}
                  visible={drawerVisible}
                  wrapClassName={css(drawerStyle)}
                >
                  <MobileLogo />
                  {this.drawMenu({ theme: 'light' })}
                </Drawer>
              </Responsive>
            </ContentWrapper>
          )}
        </ClassNames>
      </HeaderWrapper>
    );
  }
}

Header.propTypes = {
  _: PropTypes.func.isRequired,
  history: PropTypes.history.isRequired,
  location: PropTypes.location.isRequired,
  routes: PropTypes.list.isRequired,
  transparentHeader: PropTypes.bool.isRequired,
};

export default compose(
  withRouter,
  withTranslate,
)(Header);
