import { Col, Row, Spin } from 'antd';
import { Page } from 'components';
import { Map } from 'immutable';
import { noop } from 'lodash';
import { fetchContent } from 'reducers/content/actions';
import { Component, compose, connect, styled, PropTypes, React } from 'utils/create';

const StyledPage = styled(Page)({
  textAlign: 'center',
});

const Item = styled.a({
  display: 'block',
  margin: '1rem',

  '&:hover img': {
    opacity: 0.5,
  },
});

class ContentPage extends Component {
  componentDidMount() {
    const { handleFetchContent, id } = this.props;

    handleFetchContent('data-references', id);
  }

  render() {
    const { content } = this.props;

    if (content.isEmpty()) {
      return <Spin />;
    }

    return (
      <StyledPage>
        <img src={content.get('icon')} alt={content.get('title')} />
        <h1>{content.get('title')}</h1>
        <Row justify="space-around" type="flex">
          {content
            .get('items')
            .filter(item => !item.get('hidden'))
            .map(item => (
              <Col key={item.get('name')} span={8}>
                <Item href={item.get('path')}>
                  <img src={item.get('image')} alt={item.get('name')} />
                  <strong>{item.get('name')}</strong>
                </Item>
              </Col>
            ))}
        </Row>
      </StyledPage>
    );
  }
}

ContentPage.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.map,
  handleFetchContent: PropTypes.func,
};

ContentPage.defaultProps = {
  content: Map(),
  handleFetchContent: noop,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => ({
  id,
  content: state.getIn(['content', 'data', 'data-references', id]),
});

const mapDispatchToProps = {
  handleFetchContent: fetchContent,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ContentPage);
