import Lightbox from 'react-images';
import PhotoGallery from 'react-photo-gallery';
import { Component, styled, PropTypes, React } from 'utils/create';
import PhotoX from './Photo';

const PhotoWrapper = styled.div({
  '& .react-photo-gallery--gallery div': {
    justifyContent: 'center',
  },
});

class Photo extends Component {
  state = {
    preview: false,
    currentImage: 0,
  };

  handleClose = () => {
    this.setState({ preview: false });
  };

  handleNext = () => {
    const { currentImage } = this.state;
    this.setState({ currentImage: currentImage + 1 });
  };

  handlePhotoClick = (e, { index }) => {
    this.setState({ preview: true, currentImage: index });
  };

  handlePrevious = () => {
    const { currentImage } = this.state;
    this.setState({ currentImage: currentImage - 1 });
  };

  render() {
    const { width, height, src, title } = this.props;
    const { currentImage, preview } = this.state;

    return (
      <PhotoWrapper>
        <PhotoGallery
          onClick={this.handlePhotoClick}
          columns={1}
          photos={[{ width, height, src }]}
          ImageComponent={props => <PhotoX {...props} isSingle />}
        />
        <Lightbox
          images={[{ width, height, src, caption: title }]}
          backdropClosesModal
          imageCountSeparator=" / "
          currentImage={currentImage}
          isOpen={preview}
          onClose={this.handleClose}
          onClickPrev={this.handlePrevious}
          onClickNext={this.handleNext}
          preventScroll={false}
        />
      </PhotoWrapper>
    );
  }
}

Photo.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Photo;
