import { Gallery, Page } from 'components';
import { React } from 'utils/create';
import assets from './assets';

const HistoryPage = () => (
  <Page>
    <h1>Przedsięwzięcie 3000</h1>

    <h2>Geneza</h2>

    <p>
      26 lutego 1965 roku na poligonie w Drawsku Pomorskim odbyły się połączone polsko-radzieckie
      manewry wojskowe. Podczas ćwiczeń dowództwo Sztabu Zjednoczonych Sił Zbrojnych Układu
      Warszawskiego zaplanowało wystrzelenie balistycznych rakiet taktyczno-operacyjnych R-11M (w
      kodzie NATO Scud A) zdolnych do przenoszenia głowic jądrowych. Skuteczne wykorzystanie
      taktycznych sił jądrowych podczas inicjalnego etapu przyszłej wojny w Europie, było
      postrzegane jako krytyczne dla powodzenia późniejszej ofensywy prowadzonej siłami
      konwencjonalnymi.
    </p>
    <p>
      Manewry na poligonie w Drawsku Pomorskim miały jeszcze jeden cel. W czasie ich trwania
      zamierzano sprawdzić, ile czasu zajmie przetransportowanie broni atomowej z magazynów
      znajdujących się na terytorium ZSRR do Polski i przygotowanie jej do użycia przeciwko siłom
      NATO. W tym celu przetestowano cztery drogi transportu: morską, kolejową, powietrzną oraz
      drogową.
    </p>
    <p>
      Ćwiczenia wykazały, że czas niezbędny na wykonanie wszystkich działań związanych z dostawą
      głowic był zbyt długi. Konwoje były ponadto wystawione na potencjalne ataki z powietrza,
      nieoczekiwane przeszkody i opóźnienia oraz działania sabotujące. W warunkach wojny trudno
      byłoby utrzymać tak dużą operację w ścisłej tajemnicy. Po przeanalizowaniu tych okoliczności
      stwierdzono, że w wypadku wojny przeciwko NATO w Europie, siły Układu Warszawskiego nie będą
      mogły odpowiedzieć na czas pełnią swojego taktycznego arsenału jądrowego. Taka sytuacja
      mogłaby nawet przypieczętować los całej wojny.
    </p>
    <p>
      Pokłosiem ćwiczeń na Poligonie w Drawsku Pomorskim była decyzja o budowie magazynów amunicji
      specjalnej na obszarze Polski. W ten sposób byłyby one bliżej zachodniej granicy z NATO, nadal
      jednak zbyt daleko, by można je było skutecznie wykorzystać do rażenia celów w Zachodniej
      Europie. Biorąc pod uwagę potencjał technologiczny ówczesnych środków przenoszenia, a także
      ich celność trzeba się bowiem było liczyć z ryzykiem transportu zestawów rakietowych i
      amunicji jądrowej przez terytorium NRD, do wyznaczonych miejsc wystrzelenia rakiet. W tym
      samym czasie podobne bazy atomowe powstały w NRD, Czechosłowacji, na Węgrzech oraz w Bułgarii.
    </p>
    <p>
      Realizację przedsięwzięcia budowy magazynów amunicji atomowej na terenie Polski rozpoczęto w
      1966 roku i nadano mu kryptonim &quot;Wisła&quot;.
    </p>

    <Gallery
      images={[
        {
          original: assets.ilustracja1,
          width: 1600,
          height: 972,
          description:
            'Zobrazowanie satelitarne z programu Hexagon przedstawiające poligon w Drawsku Pomorskim. Misja: 1210-5. Data wykonania: 26.06.1975.',
        },
      ]}
    />

    <h2>Akta zadania specjalnego &quot;Wisła&quot;</h2>

    <p>
      W dniach 3-11 listopada 1966 roku specjaliści wojskowi przeprowadzili rekonesans, który miał
      wskazać najbardziej korzystne miejsca do budowy planowanych baz. Spośród rekomendowanych
      obszarów radzieccy dowódcy dokonali wyboru trzech. Były to: Podborsko (Dobrowo) w pobliżu
      Białogardu (obiekt 3001), Brzeźnica-Kolonia koło Jastrowia (obiekt 3002) i Templewo (Grochowo)
      nieopodal Trzemeszna Lubuskiego (obiekt 3003).
    </p>
    <p>
      Głównymi czynnikami determinującymi taki wybór była bliskość zgrupowań armii radzieckiej,
      lotnisk oraz jednostek rakietowych i sił powietrznych zdolnych do przenoszenia broni jądrowej,
      a także niewielka odległość od linii kolejowej i obecność sieci utwardzonych dróg. Preferowano
      krajobrazy zalesione, z niewielkimi wzniesieniami, ponieważ w takim środowisku znacznie
      łatwiej było zamaskować i chronić obiekty specjalne. Wszystkie trzy bazy powstały na skrajach
      intensywnie użytkowanych poligonów wojskowych. Miało to w teorii spowodować, by ich budowa nie
      będzie przyciągała zbyt wielkiej uwagi fotointerpretatorów NATO analizujących szpiegowskie
      zobrazowania satelitarne. Zakładano bowiem, że nowe konstrukcje, na terenie, na którym
      nieustannie dochodzi do ruchów wojsk i ciągle coś się buduje umkną ich uwadze. Jak się później
      okazało, takie przekonanie było błędne.
    </p>
    <p>
      25 lutego 1967 roku reprezentant Ministerstwa Obrony PRL marszałek Marian Spychalski i
      przedstawiciel Ministerstwa Obrony ZSRR marszałek Andrei Grechko podpisali w Moskwie
      &quot;Porozumienie między Rządem Polskiej Rzeczpospolitej Ludowej a Rządem Związku
      Socjalistycznych Republik Radzieckich o środkach podjętych w zakresie podwyższenia gotowości
      bojowej wojsk&quot;. Uzgodniono, że na terytorium PRL będzie rozmieszczona broń atomowa i
      niezbędne do jej wykorzystania zespoły montażowe. Dokument ustalał tok prac nad trzema
      magazynami amunicji jądrowej. Strona Polska zobowiązała się do ich budowy na własny koszt,
      zgodnie z projektem przedstawionym przez ZSRR. Wyposażenie techniczne obiektów specjalnych,
      niezbędne do przechowywania i kontrolowania głowic atomowych, miało być natomiast dostarczone
      przez Związek Radziecki. Po zakończeniu budowy postanowiono, że bazy będą przekazane armii
      radzieckiej w wyłączne użytkowanie. Zgodnie z porozumieniem, w razie wybuchu wojny, głowice
      przechowywane w magazynach, miały być także przekazane siłom Polskiego Wojska Ludowego, ale
      nie sprecyzowano w jakim trybie.
    </p>
    <p>
      Prace budowlane realizowano w ścisłej tajemnicy. Wydano specjalne instrukcje dotyczące metod
      maskowania i dezinformacji. Oddziały inżynieryjne LWP poinformowano, że budują schrony dla
      radzieckich jednostek łączności na wypadek wojny. 12 grudnia 1969 roku ukończone bazy
      oficjalnie przekazano wojskom radzieckim. Wydano wówczas nową instrukcję, nakazującą
      zniszczenie lub przekazanie Moskwie niektórych wrażliwych dokumentów, planów budowlanych oraz
      geodezyjnych.
    </p>

    <Gallery
      images={[
        {
          original: assets.ilustracja2,
          width: 1600,
          height: 1063,
          description: 'Wejście do schronu "Monolit" w Podborsku.',
        },
        {
          original: assets.ilustracja3,
          width: 1600,
          height: 1131,
          description:
            'Składy amunicji specjalnej w Polsce na tle innych obiektów przystosowanych do przechowywania broni jądrowej w państwach sąsiednich.',
        },
      ]}
    />

    <h2>Inne obiekty potencjalnie związane z obecnością broni jądrowej w Polsce</h2>

    <p>
      W drugiej połowie lat 70. XX wieku (najprawdopodobniej pod koniec dekady), w każdej z trzech
      opisywanych wcześniej baz, powstał schron typu Granit. Była to konstrukcja zbudowana z
      żelbetonowych prefabrykatów, z dwoma bramami po przeciwległych stronach. Tego typu budowle
      skonstruowano także w innych miejscach zajmowanych przez wojska radzieckie: na lotniskach w
      Bagiczu, Kluczewie i Chojnie oraz w bazie w Pstrążu. Ich przeznaczenie nie jest do końca
      wyjaśnione. Wiadomo jednak, że niektóre z nich na terenie ZSRR były wykorzystywane do
      przechowywania rakiet S-200 klasy ziemia-powietrze, mogących przenosić głowice jądrowe. W
      sąsiedztwie lotniska w Szprotawie (wieś Wiechlice) istniał także kompleks, który mógł być
      przeznaczony do przechowywania broni masowego rażenia. Wszystkie te obiekty powstały jednak
      poza oficjalnym obiegiem, najprawdopodobniej bez informowania strony polskiej o ich
      skonstruowaniu. W związku z tym nie zachowały się żadne znane dokumenty, które mogłyby rzucić
      światło na okoliczności ich powstania i funkcję.
    </p>

    <Gallery
      images={[
        {
          original: assets.ilustracja4,
          width: 1600,
          height: 1063,
          description: 'Front schronu Granit w Podborsku.',
        },
      ]}
    />

    <h2>Archeologia i historia w badaniach Zimnej Wojny</h2>

    <p>
      Wiedza na temat kontekstu historycznego jest bardzo istotna dla zrozumienia archeologicznych
      pozostałości okresu Zimnej Wojny. Archiwalne dokumenty oferują wartościowy wgląd w losy takich
      miejsc, rzadko kiedy są jednak skończonym i kompletnym źródłem wiedzy. Raporty wojskowe,
      porozumienia rządowe i dokumenty urzędowe prezentują zazwyczaj jeden, wyłączny i bardzo ogólny
      punkt widzenia.
    </p>
    <p>
      Akta przedsięwzięcia &quot;Wisła&quot; zostały odtajnione w 2006 roku i są obecnie
      przechowywane w archiwach Instytutu Pamięci Narodowej. Składają się one z trzech tomów
      zawierających umowy międzyrządowe, uzgodnienia dotyczące kolejnych etapów budowy obiektów
      specjalnych, protokoły odbiorcze, notatki służbowe oraz szereg innych pism. Większość
      dokumentów w aktach projektu &quot;Wisła&quot; zostało sporządzonych do 12 grudnia 1969 roku,
      a więc do momentu, w którym nowo wybudowane bazy przekazano w użytkowanie wojskom radzieckim.
      Po tym okresie w zbiorze pojawiło się stosunkowo niewiele nowych źródeł. Można zatem
      stwierdzić, że historia radzieckich baz atomowych w Polsce, widziana z perspektywy archiwów,
      kończy się w grudniu 1969 roku i rozpoczyna na nowo na początku lat 90. XX wieku, po wycofaniu
      się wojsk rosyjskich z Polski. Opinia publiczna została wówczas poinformowana, że wbrew
      wielokrotnym deklaracjom, Polska nie była wcale obszarem wolnym od broni masowego rażenia.
    </p>
    <p>
      Projekt ten ma na celu uzupełnienie ponad 23 letniej luki w wiedzy na temat dziejów
      radzieckich magazynów broni atomowej w Polsce, właśnie za pośrednictwem badań archeologicznych
      i reinterpretacji źródeł historycznych.
    </p>

    <Gallery
      images={[
        {
          original: assets.ilustracja5,
          width: 1600,
          height: 1213,
          description: 'Wycinek artykułu z gazety "Super Express" z 12 kwietnia 1996 roku.',
        },
      ]}
    />
  </Page>
);

export default HistoryPage;
