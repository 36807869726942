/* eslint-disable */

export default [
  {
    original: require('./Ilustracja_1.jpg'),
    thumbnail: require('./Ilustracja_1_thumb.jpg'),
    width: 1200,
    height: 849,
    description:
      'Fotografie obiektu 3001 w pobliżu Podborska (Dobrowa). Po lewej ocenzurowane zdjęcie wykonane w 1975 roku. Z prawej strony fotografia lotnicza z 17 czerwca 1996 roku. Na powiększeniach widoczne są m.in.: strefa ścisła ze schronami i budynkami towarzyszącymi; zabudowania koszarów i części garażowej; dwa lądowiska dla helikopterów.',
    tags: ['teledetekcja fotografie lotnicze'],
  },
  {
    original: require('./Ilustracja_2.jpg'),
    thumbnail: require('./Ilustracja_2_thumb.jpg'),
    width: 1200,
    height: 682,
    description: 'Borne Sulinowo na zobrazowaniu satelitarnym wykonanym 28 lipca 1969 roku.',
    tags: ['corona'],
  },
  {
    original: require('./Ilustracja_3.jpg'),
    thumbnail: require('./Ilustracja_3_thumb.jpg'),
    width: 825,
    height: 618,
    description:
      'Pas zapasowy lotniska w Chojnie z samolotami Mig-21 i MIg-15 (lub Mig-17). Zdjęcie wykonane 27 kwietnia 1964 roku. Rozdzielczość zobrazowania pozwala na zidentyfikowanie typów samolotów.',
    tags: ['corona'],
  },
  {
    original: require('./Ilustracja_4.jpg'),
    thumbnail: require('./Ilustracja_4_thumb.jpg'),
    width: 1200,
    height: 843,
    description:
      'Obiekt 3001 w pobliżu Podborska (Dobrowa) na zobrazowaniu wykonanym 28 lipca 1969 roku.',
    tags: ['corona'],
  },
  {
    original: require('./Ilustracja_5.jpg'),
    thumbnail: require('./Ilustracja_5_thumb.jpg'),
    width: 1200,
    height: 549,
    description: 'Chmura punktów z lotniczego skanowania laserowego.',
    tags: ['laserowe'],
  },
  {
    original: require('./Ilustracja_6.jpg'),
    thumbnail: require('./Ilustracja_6_thumb.jpg'),
    width: 1200,
    height: 849,
    description:
      '. Obiekt 3001 w Podborsku. Przetworzone dane z lotniczego skanowania laserowego pozwalają na zarejestrowanie różnych śladów pozostawionych w terenie w związku z funkcjonowaniem bazy i ćwiczeniami wojskowymi: pozostałości strzelnicy, budynków, rowów strzeleckich, schronów na pojazdy i dróg zapasowych.',
    tags: ['laserowe'],
  },
  {
    original: require('./Ilustracja_7.jpg'),
    thumbnail: require('./Ilustracja_7_thumb.jpg'),
    width: 1200,
    height: 642,
    description: 'Wygląd głównego okna programu QGIS.',
    tags: ['gis'],
  },
];
