import { Card, Col, Row } from 'antd';
import { Page } from 'components';
import { Map } from 'immutable';
import { noop } from 'lodash';
import { withRouter } from 'react-router-dom';
import Truncate from 'react-truncate';
import { fetchContentList } from 'reducers/content/actions';
import { Component, compose, connect, PropTypes, React, styled } from 'utils/create';

const More = styled.div({
  marginTop: '1rem',
  textAlign: 'right',
});

class NewsPage extends Component {
  componentDidMount() {
    const { handleFetchContentList, newsList } = this.props;
    if (newsList.isEmpty()) {
      handleFetchContentList('news');
    }
  }

  render() {
    const { history, newsList } = this.props;

    return (
      <Page>
        <h1>Aktualności</h1>

        <Row type="flex" justify="center" gutter={8}>
          {newsList.toList().map(news => (
            <Col sm={8} key={news.get('slug')}>
              <Card
                hoverable
                cover={<img alt={news.get('title')} src={news.get('featured')} />}
                onClick={() => history.push(`/aktualnosci/${news.get('slug')}`)}
              >
                <Card.Meta
                  title={news.get('title')}
                  description={
                    <div>
                      <Truncate
                        lines={4}
                        ellipsis={
                          <span>
                            ... <More>Czytaj dalej...</More>
                          </span>
                        }
                      >
                        {news.get('content').replace(/#/g, '')}
                      </Truncate>
                    </div>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      </Page>
    );
  }
}

NewsPage.propTypes = {
  history: PropTypes.history.isRequired,
  handleFetchContentList: PropTypes.func,
  newsList: PropTypes.map,
};

NewsPage.defaultProps = {
  handleFetchContentList: noop,
  newsList: Map(),
};

const mapStateToProps = state => ({
  newsList: state.getIn(['content', 'data', 'news']),
});

const mapDispatchToProps = {
  handleFetchContentList: fetchContentList,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NewsPage);
