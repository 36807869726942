import { fromJS, OrderedMap } from 'immutable';
import typeToReducer from 'type-to-reducer';
import * as types from './types';

const initialState = fromJS({
  isLoading: false,
  error: null,
  data: {},
});

export default typeToReducer(
  {
    [types.CONTENT_LIST]: {
      PENDING: state => state.merge({ isLoading: true, error: null }),
      REJECTED: (state, { error }) => state.merge({ isLoading: false, error }),
      FULFILLED: (state, { meta: { type }, payload }) =>
        state.merge({
          isLoading: false,
          error: null,
          data: { [type]: OrderedMap(payload.map(item => [item.slug, fromJS(item)])) },
        }),
    },
    [types.CONTENT_GET]: {
      PENDING: state => state.merge({ isLoading: true, error: null }),
      REJECTED: (state, { error }) => state.merge({ isLoading: false, error }),
      FULFILLED: (state, { meta: { type, id }, payload }) =>
        state
          .merge({
            isLoading: false,
            error: null,
          })
          .setIn(['data', type, id], fromJS(payload)),
    },
  },
  initialState,
);
