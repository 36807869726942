import { Gallery, Page } from 'components';
import { withTranslate } from 'helpers';
import { compose, PropTypes, React } from 'utils/create';
import assets from './assets';

const GalleryPage = ({ _ }) => (
  <Page>
    <h1>{_('gallery.header')}</h1>

    <h2>Obiekt specjalny w Wiechlicach k. Szprotawy.</h2>
    <Gallery images={assets.filter(asset => asset.tags.includes('Wiechlice'))} />

    <h2>Obiekt 3001 (Podborsko, Dobrowo)</h2>
    <Gallery images={assets.filter(asset => asset.tags.includes('Obiekt 3001'))} />

    <h2>Obiekt 3002 (Brzeźnica-Kolonia)</h2>
    <Gallery images={assets.filter(asset => asset.tags.includes('Obiekt 3002'))} />

    <h2>Obiekt 3003 (Templewo, Grochowo)</h2>
    <Gallery images={assets.filter(asset => asset.tags.includes('Obiekt 3003'))} />
  </Page>
);

GalleryPage.propTypes = {
  _: PropTypes.func.isRequired,
};

export default compose(withTranslate)(GalleryPage);
