/* eslint-disable */

import { Col, Row } from 'antd';
import { Page } from 'components';
import { Component, React, styled } from 'utils/create';

const Img = styled.img({
  maxWidth: '100%',
});

const MediaList = styled.ul({
  paddingLeft: '1rem',
  margin: '1rem',
});

const items = [
  {
    name:
      'Audycja w Polskim Radiu RDC "Z innej planety: zdjęcia satelitów szpiegowskich w pracy archeologów"',
    url:
      'http://www.rdc.pl/podcast/z-innej-planety-odtajnione-zdjecia-satelitow-szpiegowskich-usa/',
  },
  {
    name: 'Audycja w Radiu Zachód "Obiekt 3003"',
    url: 'http://www.zachod.pl/audycja/obiekt-3003/',
  },
  {
    name: 'Artykuł na portalu Nauka w Polsce',
    url:
      'http://naukawpolsce.pap.pl/aktualnosci/news%2C31296%2Codkryto-nieznane-elementy-radzieckich-baz-atomowych-w-polsce.html',
  },
  {
    name: 'Artykuł w portalu Space24',
    url:
      'https://www.space24.pl/dane-satelitarne-pomogly-zmapowac-dawne-sowieckie-bazy-atomowe-w-polsce',
  },
  {
    name: 'Artykuł na portali Interia',
    url:
      'https://menway.interia.pl/historia/news-radzieckie-bazy-atomowe-w-polsce-nowe-odkrycia,nId,2641829',
  },
  {
    name: 'Artykuł na portalu onet',
    url: 'https://poznan.onet.pl/odkryto-pozostalosci-radzieckich-baz-atomowych/b0kr6z1',
  },
  {
    name: 'Artykuł na portalu o2',
    url:
      'https://www.o2.pl/artykul/tajemnice-sowieckich-baz-atomowych-w-polsce-wazne-odkrycie-historyka-6302972513589377a',
  },
];

class MediaPage extends Component {
  render() {
    return (
      <Page>
        <h1>Media o projekcie</h1>

        <Row type="flex" justify="space-around" align="middle">
          <Col span={12}>
            <Img src="/mocks/Plakat-B1_3.png" alt="Trzecia Zona" />
          </Col>
          <Col span={12}>
            <MediaList>
              {items.map(({ name, url }) => (
                <li key={url}>
                  <a href={url}>{name}</a>
                </li>
              ))}
            </MediaList>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default MediaPage;
