import { Card, Col, Row } from 'antd';
import { Content, ContentWrapper, Logo, Photo } from 'components';
import Contact from 'containers/Contact';
import bg from 'containers/GalleryPage/assets/Obiekt_3003/c12.jpg';
import { withTranslate } from 'helpers';
import { Map } from 'immutable';
import { noop } from 'lodash';
import Truncate from 'react-truncate';
import { withRouter } from 'react-router-dom';
import { fetchContentList } from 'reducers/content/actions';
import { Component, compose, connect, PropTypes, React, styled } from 'utils/create';
import { colors } from 'utils/variables';
import assets from './assets';

const HomePageWrapper = styled.div({
  overflow: 'hidden',
});

const Splash = styled.header({
  height: '90vh',
  backgroundImage: `url("${bg}")`,
  backgroundPosition: `right bottom`,
  backgroundSize: 'cover',
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
});

const StyledLogo = styled(Logo)({
  height: '10rem',
  margin: '2rem auto',
  display: 'block',
  fill: colors.white,
});

const Title = styled.h1({
  margin: 0,
  textTransform: 'uppercase',
  color: colors.white,
  fontSize: '4rem',
  fontWeight: 'bold',
  textShadow: `0 0 5px #000`,
});

const Subtitle = styled.h2({
  color: colors.white,
  fontSize: '1.5rem',
  fontWeight: 400,
  margin: '2rem 0 4rem',
  textShadow: `0 0 5px #000`,
});

const SplashButtons = styled(Row)({
  margin: '1rem',
});

const SplashButton = styled(Col)({
  cursor: 'pointer',
  margin: '0 1rem',

  '&:hover': {
    opacity: 0.5,
  },
  '& img': {
    margin: 0,
  },
  '& span': {
    display: 'block',
    color: colors.white,
    textTransform: 'uppercase',
    whiteSpace: 'pre',
    fontSize: '0.75rem',
  },
});

const Triangles = styled.section({
  position: 'relative',
  zIndex: 1,
  padding: '1rem 0',

  '&::before, &::after': {
    content: `''`,
    position: 'absolute',
    width: 0,
    height: 0,
    borderBottom: `1670px solid ${colors.background}`,
    top: '-1670px',
    left: '50%',
    zIndex: -10,
    pointerEvents: 'none',
  },
  '&::before': {
    borderRight: '10000px solid transparent',
    transform: 'translateX(-100%)',
  },
  '&::after': {
    borderLeft: '10000px solid transparent',
  },
});

const Section = styled.section({
  padding: '2rem 0',
});

const H1 = styled.h1({
  textAlign: 'center',
  margin: '2rem 0',
  fontSize: '3rem',
});

const More = styled.div({
  marginTop: '1rem',
  textAlign: 'right',
});

const Logos = styled(Row)({
  '& img': {
    maxWidth: '100%',
    padding: '3rem',
  },
});
const LogosText = styled.p({
  margin: '-3rem 0 0',
  color: colors.lightGray,
  fontSize: '0.7rem',
  opacity: 0.5,
});

const MoreNews = styled.div({
  textAlign: 'center',

  '& span': {
    cursor: 'pointer',
    fontWeight: 'bold',
    border: `1px solid ${colors.black}`,
    display: 'inline-block',
    margin: '2rem 0',
    padding: '1rem',
    opacity: 0.5,

    '&:hover': {
      opacity: 1,
      borderStyle: 'dashed',
    },
  },
});

const splashButtons = [
  {
    key: 'geoportal',
    title: 'Atomowy Geoportal',
    path: '/geoportal',
  },
  {
    key: 'modele3d',
    title: 'Modele 3D',
    path: '/modele-3d',
  },
  {
    key: 'zwiedzanie',
    title: 'Wirtualne Zwiedzanie',
    path: '/wirtualne-zwiedzanie',
  },
  {
    key: 'makiety',
    title: 'Makiety',
    path: '/makiety',
  },
];

class HomePage extends Component {
  componentDidMount() {
    const { handleFetchContentList, newsList } = this.props;
    if (newsList.isEmpty()) {
      handleFetchContentList('news');
    }
  }

  render() {
    const { _, history, newsList } = this.props;

    return (
      <HomePageWrapper>
        <Splash>
          <StyledLogo />
          <Title>{_('title')}</Title>

          <SplashButtons type="flex" justify="center">
            {splashButtons.map(({ key, path, title }) => (
              <SplashButton key={key} xs={4} sm={4} md={2} onClick={() => history.push(path)}>
                <img src={assets.splash[key]} alt={title} />
                <span>{title.replace(' ', '\n')}</span>
              </SplashButton>
            ))}
          </SplashButtons>

          <Subtitle>{_('subtitle')}</Subtitle>
        </Splash>

        <Triangles />

        <Section>
          <ContentWrapper>
            <H1>Aktualności</H1>

            <Row type="flex" justify="center" gutter={8}>
              {newsList
                .toList()
                .slice(0, 6)
                .map(news => (
                  <Col sm={8} key={news.get('slug')}>
                    <Card
                      hoverable
                      cover={<img alt={news.get('title')} src={news.get('featured')} />}
                      onClick={() => history.push(`/aktualnosci/${news.get('slug')}`)}
                    >
                      <Card.Meta
                        title={news.get('title')}
                        description={
                          <div>
                            <Truncate
                              lines={4}
                              ellipsis={
                                <span>
                                  ... <More>Czytaj dalej...</More>
                                </span>
                              }
                            >
                              {news.get('content').replace(/#/g, '')}
                            </Truncate>
                          </div>
                        }
                      />
                    </Card>
                  </Col>
                ))}
            </Row>
            <MoreNews onClick={() => history.push('/aktualnosci')}>
              <span>Zobacz wszystkie wpisy</span>
            </MoreNews>
          </ContentWrapper>
        </Section>

        <Section style={{ backgroundColor: '#ddd9cb' }}>
          <Content>
            <H1>O projekcie</H1>

            <h2>Atomowi żołnierze wolności</h2>
            <p>
              <strong>
                Minęło ponad 25 lat od momentu, w którym radzieckie magazyny taktycznych i
                taktyczno-operacyjnych głowic jądrowych znajdujących się w Polsce zostały opuszczone
                przez wycofujące się wojska rosyjskie. Wyjeżdżający żołnierze zabrali ze sobą wiele
                tajemnic.
              </strong>
            </p>
            <div style={{ width: '30%', float: 'left', margin: '0 1rem 1rem' }}>
              <Photo width={1412} height={2000} src="/mocks/Plakat-B1_2.png" title="Trzecia Zona" />
            </div>

            <p>
              Jedną z nich był mit Polski jako strefy wolnej od broni jądrowej. Poprzez
              archeologiczne studia opuszczonych baz oraz instalacji znajdujących się na dawnych
              lotniskach wojskowych możemy dziś obalić ten mit oraz uzupełnić wiedzę o warunkach
              przechowywania broni masowego rażenia na terytorium kraju. Przyjęcie perspektywy
              archeologicznej, w pewnych okolicznościach, pozwala także na przewartościowanie
              niektórych poglądów naukowych i mitów.
            </p>
            <p>
              Do niedawna zimna wojna leżała przede wszystkim w sferze zainteresowań historyków,
              którzy badali ją na podstawie dostępnych źródeł archiwalnych. Dziś okresem tym zajmują
              się także archeolodzy prowadzący studia nad materialnymi reliktami współczesnych
              konfliktów. W tym nurcie realizowany jest grant w Katedrze Archeologii Uniwersytetu
              Szczecińskiego, który nosi tytuł &quot;Radzieckie magazyny głowic atomowych w Polsce.
              Pomiędzy dziedzictwem i zapomnieniem. Perspektywa archeologiczna&quot;. Badania są
              dofinansowane ze środków Ministra Kultury i Dziedzictwa Narodowego z Funduszu Promocji
              Kultury (umowa nr 2589/18/FPK/NID) w ramach programu &quot;Ochrona zabytków
              archeologicznych&quot; i będą trwały w latach 2018-2019. Program jest pilotowany przez
              Narodowy Instytut Dziedzictwa. Celem tej strony internetowej jest prezentacja postępów
              w prowadzonych pracach badawczych.
            </p>

            <h2>Cele grantu</h2>
            <p>
              Projekt ma trzy podstawowe cele. Pierwszy z nich można określić jako konserwatorski.
              Wynika on z charakterystyki programu, z którego zostały pozyskane środki. Wiąże z
              potrzebą zarejestrowania za pomocą nieinwazyjnych metod archeologicznych pozostałości
              opuszczonych instalacji wojskowych i określeniem ich stanu zachowania.
            </p>
            <p>
              Drugie zadanie to próba oceny potencjału poznawczego metod teledetekcyjnych w studiach
              nad zimną wojną. Jego realizacja wymaga integracji i krytyki informacji przestrzennych
              pochodzących z różnych źródeł (zdjęć lotniczych, zobrazowań satelitarnych, lotniczego
              skanowania laserowego, map) oraz ich konfrontacji z dokumentacją archiwalną, zdjęciami
              i relacjami świadków.
            </p>
            <p>
              Trzeci cel wiąże się ze społecznym funkcjonowaniem badanych obiektów. Pozostałości
              opuszczonych instalacji zimnowojennych od pewnego czasu cieszą się rosnącym
              zainteresowaniem entuzjastów historii. Sytuacja ta pozwala na zaobserwowanie procesu,
              w rezultacie którego do niedawna pozbawione znaczenia miejsca stają się dziedzictwem
              kulturowym. Archeolodzy bardzo rzadko poszukują odpowiedzi na pytanie dlaczego
              niektóre przedmioty i budowle uznajemy za „zabytki”, które warto chronić, a inne
              odchodzą w niepamięć? Czy główną rolę odgrywa w tym przypadku długotrwałość ich
              istnienia, czy jest to jednak przede wszystkim przewartościowanie sposobu, w jaki
              funkcjonują one w dyskursie społecznym i nadanie im nowych znaczeń? Co tak na prawdę
              definiuje ich kulturową wartość: ich wiek i odmienność, czy pilnie strzeżona
              tajemnica, która do niedawna była dostępna jedynie dla garstki najwyższych rangą
              oficerów, a dziś może być udziałem zwykłych ludzi? Poszukiwanie odpowiedzi na te
              pytania można w pewnym sensie traktować jako poszukiwanie legitymizacji istnienia nauk
              zajmujących się przeszłością. Badanie materialnych reliktów systemu totalitarnego
              prowadzi także do lepszego zrozumienie obecnego kontekstu społeczno-politycznego, a
              także odmienności Europy Środkowej na mapie kontynentu.
            </p>
            <h2>Podziękowania</h2>
            <p>
              Badania nie mogłyby być w pełni realizowane, gdyby nie przychylność osób, które od
              wielu lat interesują się tematyką magazynów atomowych w Polsce i chętnie dzielą się
              swoją wiedzą oraz odkryciami. Dziękuję za życzliwość, poświęcony czas i interesujące
              dyskusje Łukaszowi Gładysiakowi, Rafałowi Sełomonikowi, Krzysztofowi Pawluchowi,
              Arturowi Kaszkurowi, Pawłowi Urbaniakowi i Robertowi Dziębie.
            </p>
            <p>
              Specjalne podziękowania należą się Mieczysławowi Żukowi za inspirujące rozmowy o tym,
              kim byli &quot;atomowi żołnierze wolności&quot;, za pomoc w żmudnym odtwarzaniu
              pierwotnego wyglądu bazy w Podborsku i bezinteresowne dzielenie się zebraną wiedzą i
              źródłami.
            </p>
            <p>
              Podziękowania należą się także Dyrektorowi Muzeum Oręża Polskiego w Kołobrzegu
              Aleksandrowi Ostaszowi i pracownikom Nadleśnictw w Białogardzie, Jastrowiu i Sulęcinie
              za życzliwość, udzielenie mi wszechstronnej pomocy oraz wyrażenie zgody na realizację
              badań na terenach, którymi się opiekują.
            </p>

            <Logos type="flex" align="middle" justify="center">
              <Col xs={6}>
                <img src={assets.mkdin} alt="Ministerstwo Kultury i Dziedzictwa Narodowego" />
                <LogosText>
                  Dofinansowano ze środków Ministra Kultury i Dziedzictwa Narodowego pochodzących z
                  Funduszu Promocji Kultury.
                </LogosText>
              </Col>
              <Col xs={6}>
                <img src={assets.nid} alt="Narodowy Instytut Dziedzictwa" />
              </Col>
              <Col xs={6}>
                <img src={assets.kaus} alt="Katedra Archeologii US" />
              </Col>
              <Col xs={6}>
                <img src={assets.us} alt="Uniwersytet Szczeciński" />
              </Col>
            </Logos>
          </Content>
        </Section>

        <Section>
          <ContentWrapper>
            <Contact />
          </ContentWrapper>
        </Section>
      </HomePageWrapper>
    );
  }
}

HomePage.propTypes = {
  _: PropTypes.func.isRequired,
  history: PropTypes.history.isRequired,
  handleFetchContentList: PropTypes.func,
  newsList: PropTypes.map,
};

HomePage.defaultProps = {
  handleFetchContentList: noop,
  newsList: Map(),
};

const mapStateToProps = state => ({
  newsList: state.getIn(['content', 'data', 'news']),
});

const mapDispatchToProps = {
  handleFetchContentList: fetchContentList,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslate,
  withRouter,
)(HomePage);
