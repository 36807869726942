/* eslint-disable */
import { Layout } from 'antd';
import {
  ContentPage,
  GalleryPage,
  Header,
  HistoryPage,
  HomePage,
  MapsPage,
  MediaPage,
  NewsPage,
  RemoteSensingPage,
  SingleNewsPage,
} from 'containers';
import { withTranslate } from 'helpers';
import { fromJS } from 'immutable';
import { matchPath, Route, Switch, withRouter } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Component, compose, connect, PropTypes, React, styled } from 'utils/create';
import { colors } from 'utils/variables';

const StyledLayout = styled(Layout)({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled(Layout.Content)({
  flex: 1,
  fontSize: '1rem',
  backgroundColor: colors.background,
});

const Footer = styled(Layout.Footer)({});

class App extends Component {
  state = {
    routes: fromJS([
      { key: 'main', exact: true, path: '/', component: HomePage },
      { key: 'news', exact: true, path: '/aktualnosci', component: NewsPage },
      { key: 'singleNews', path: '/aktualnosci/:slug', hidden: true, component: SingleNewsPage },
      { key: 'history', path: '/historia', component: HistoryPage },
      { key: 'remoteSensing', path: '/teledetekcja', component: RemoteSensingPage },
      { key: 'models', path: '/modele', hidden: true },
      { key: 'gallery', path: '/galeria', component: GalleryPage },
      { key: 'project', path: '/projekt', hidden: true },
      { key: 'maps', path: '/mapy', hidden: true, component: MapsPage },
      { key: 'media', path: '/media', component: MediaPage },
      { key: 'contact', path: '/#kontakt' },
      {
        key: 'facebook',
        path: 'https://www.facebook.com/TrzeciaZona/',
        icon: 'facebook',
        iconTheme: 'filled',
        tooltip: true,
      },
      {
        key: 'youtube',
        path: 'https://www.youtube.com/channel/UCDATrBvfOVu7g1HlTBdCZEg',
        icon: 'youtube',
        iconTheme: 'filled',
        tooltip: true,
      },
    ]),
    transparentHeader: true,
  };

  componentDidMount() {
    const { history } = this.props;
    const { routes } = this.state;

    history.listen(location => {
      const { pathname } = location;
      const route = routes.find(item => matchPath(pathname, item.toJS()));

      this.setState({ transparentHeader: this.isHomePage(location) });

      switch (route && route.get('key')) {
        case 'contact':
          return setTimeout(animateScroll.scrollToBottom, 100);
      }

      return animateScroll.scrollToTop();
    });
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { location } = this.props;
    const { transparentHeader } = this.state;

    const value = window.pageYOffset < 100 && this.isHomePage(location);
    if (transparentHeader !== value) {
      this.setState({ transparentHeader: value });
    }
  };

  isHomePage = ({ pathname }) => pathname === '/';

  render() {
    const { _ } = this.props;
    const { routes, transparentHeader } = this.state;

    return (
      <StyledLayout>
        <Header routes={routes} transparentHeader={transparentHeader} />
        <Content>
          <TransitionGroup>
            <CSSTransition key={location.pathname} timeout={300} classNames={'fade'}>
              <Switch>
                {routes.toJS().map(route => (
                  <Route {...route} />
                ))}
                <Route path="/:id" component={ContentPage} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </Content>
        <Footer style={{ textAlign: 'center' }}>{_('footer')}</Footer>
      </StyledLayout>
    );
  }
}

App.propTypes = {
  _: PropTypes.func.isRequired,
  history: PropTypes.history.isRequired,
  location: PropTypes.location.isRequired,
  transparentHeader: PropTypes.bool,
};

App.defaultProps = {
  transparentHeader: true,
};

const mapStateToProps = state => ({
  transparentHeader: state.getIn(['ui', 'transparentHeader']),
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withTranslate,
)(App);
