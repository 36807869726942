import { Markdown, Page } from 'components';
import { Map } from 'immutable';
import { noop } from 'lodash';
import { withRouter } from 'react-router-dom';
import { fetchContentList } from 'reducers/content/actions';
import { Component, compose, connect, PropTypes, React } from 'utils/create';

class SingleNewsPage extends Component {
  componentDidMount() {
    const { handleFetchContentList } = this.props;
    handleFetchContentList('news');
  }

  render() {
    const { news } = this.props;
    return (
      !news.isEmpty() && (
        <Page>
          <h1>{news.get('title')}</h1>

          <Markdown source={news.get('content')} images={news.get('images').toJS()} />
        </Page>
      )
    );
  }
}

SingleNewsPage.propTypes = {
  handleFetchContentList: PropTypes.func,
  news: PropTypes.map,
};

SingleNewsPage.defaultProps = {
  handleFetchContentList: noop,
  news: Map(),
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { slug },
    },
  },
) => ({
  news: state.getIn(['content', 'data', 'news', slug]),
});

const mapDispatchToProps = {
  handleFetchContentList: fetchContentList,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SingleNewsPage);
