/* eslint-disable */

export default [
  {
    original: require('./Wiechlice/d0a.jpg'),
    width: 1200,
    height: 797,
    description: 'Lenin i atomowi żołnierze.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d1a.jpg'),
    width: 1200,
    height: 797,
    description: 'Schron. Wygląd z zewnątrz.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d2a.jpg'),
    width: 1200,
    height: 866,
    description: 'Budynki garażowe.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d3a.jpg'),
    width: 1200,
    height: 797,
    description:
      'Wiata przed schronem. Najprawdopodobniej znajdowała się na niej suwnica z dźwigiem.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d4.jpg'),
    width: 1200,
    height: 797,
    description: 'Wiata przed schronem. Widoczne pozostałości kamuflażu.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d5a.jpg'),
    width: 1200,
    height: 797,
    description:
      'W celu zamaskowania  schronu na jego frontowej fasadzie namalowano drzewa, a resztę obiektu przykryto warstwą ziemi i osadzono roślinnością.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d6a.jpg'),
    width: 1200,
    height: 797,
    description:
      'Zewnętrzne drzwi schronu były sterowane mechanizmem elektrycznym, a w razie jego awarii, lub braku zasilania, można je było otworzyć także ręcznie.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d7a.jpg'),
    width: 1200,
    height: 797,
    description: 'Drugie wrota z drzwiami dla personelu. ',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d8a.jpg'),
    width: 1200,
    height: 797,
    description:
      'Przez większość czasu główne wrota do schronu były zamknięte. Na co dzień chodzono do schronu przez niewielkie wejście boczne.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d9a.jpg'),
    width: 1200,
    height: 797,
    description:
      'Zanim wciśniesz przycisk otwierania/zamykania wrót daj komendę "Od wrót!". Po otrzymaniu odpowiedzi "Jest od wrót!" wykonaj rozkaz.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d10a.jpg'),
    width: 1200,
    height: 797,
    description: 'Korytarz oraz kolejna para drzwi ze stalowych płyt.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d11.jpg'),
    width: 1200,
    height: 797,
    description: 'Toaleta.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d12a.jpg'),
    width: 1200,
    height: 797,
    description: 'Wrota prowadzące do pomieszczenia magazynowego.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d13.jpg'),
    width: 1200,
    height: 797,
    description: 'Wnętrze głównego magazynu głowic.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d14a.jpg'),
    width: 1200,
    height: 797,
    description: 'Wnętrze magazynu. Widok na wyjście.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d15.jpg'),
    width: 1200,
    height: 972,
    description: 'Widok z korytarza na wyjście.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d16a.jpg'),
    width: 1200,
    height: 797,
    description: 'Urządzenia wentylacyjne na pierwszym piętrze. ',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d17a.jpg'),
    width: 1200,
    height: 797,
    description: 'Korytarz i pomieszczenia techniczne na pierwszym piętrze. ',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d18a.jpg'),
    width: 1200,
    height: 864,
    description: 'Magazyn odzieży ochronnej. ',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d19a.jpg'),
    width: 1200,
    height: 797,
    description: 'Teren obiektu był otoczony rowami strzeleckimi.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d20a.jpg'),
    width: 1200,
    height: 797,
    description: 'Indywidualny betonowy bunkier wkomponowany w linię rowów strzeleckich.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d21a.jpg'),
    width: 1200,
    height: 797,
    description: 'Podwójne ogrodzenie ze słupów betonowych i drutu kolczastego  wokół schronu.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d22.jpg'),
    width: 1200,
    height: 797,
    description: 'Czerwona gwiazda.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d23.jpg'),
    width: 1200,
    height: 797,
    description: 'Budynek sztabu.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d24.jpg'),
    width: 1200,
    height: 797,
    description: 'Wjazd na teren obiektu i stróżówka.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d25.jpg'),
    width: 1200,
    height: 797,
    description: 'Pas startowy lotniska w Wiechlicach.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Wiechlice/d26.jpg'),
    width: 1200,
    height: 797,
    description:
      'Jeden z wielu zachowanych Schronohangarów na lotnisku w Wiechlicach k. Szprotawy.',
    tags: ['Wiechlice'],
  },
  {
    original: require('./Obiekt_3001/a1.jpg'),
    thumbnail: require('./Obiekt_3001/a1_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Droga prowadząca do bazy (obecnie areszt śledczy), wyłożona płytami betonowymi.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a2.jpg'),
    thumbnail: require('./Obiekt_3001/a2_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Indywidualny bunkier betonowy włączony w linię rowów strzeleckich.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a3.jpg'),
    thumbnail: require('./Obiekt_3001/a3_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Pozostałości rowów strzeleckich wewnątrz ścisłej strefy.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a4.jpg'),
    thumbnail: require('./Obiekt_3001/a4_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Ukrycie na pojazd ciężarowy znajdujące się w ścisłej strefie.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a5.jpg'),
    thumbnail: require('./Obiekt_3001/a5_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Jeden z trwałych schronów betonowych dla załóg pojazdów.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a6.jpg'),
    thumbnail: require('./Obiekt_3001/a6_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Stały dołek strzelecki z betonowymi ścianami.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a7.jpg'),
    thumbnail: require('./Obiekt_3001/a7_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Jedno ze śmietnisk pozostawionych przez dawnych użytkowników bazy w Podborsku. Obok radzieckich czapek wojskowych i fragmentów umundurowania leżą buty dziecięce i części plastikowych zabawek. ',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a8.jpg'),
    thumbnail: require('./Obiekt_3001/a8_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Pozostałości stalowych lin zawieszonych na drzewach są najprawdopodobniej związane z wykorzystywanym systemem łączności radiowej. Na polanie obok znajdowała się szopa o lekkiej konstrukcji. ',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a9.jpg'),
    thumbnail: require('./Obiekt_3001/a9_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Widoczne na zdjęciu pochylone słupy betonowe są pozostałością po betonowym płocie, który stał w tym miejscu. ',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a10.jpg'),
    thumbnail: require('./Obiekt_3001/a10_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Azbestowa rura z ekranowanym przewodem wewnątrz. Najprawdopodobniej jest to pozostałość po systemie łączności lub przewodzie sygnalizacyjnym, który był podłączony do ogrodzenia typu sosna.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a11.jpg'),
    thumbnail: require('./Obiekt_3001/a11_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Współczesne zastosowanie słupów odzyskanych z ogrodzenia obiektu 3001. ',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a12.jpg'),
    thumbnail: require('./Obiekt_3001/a12_thumb.jpg'),
    width: 1200,
    height: 662,
    description:
      'Schron typu granit wykonany z prefabrykatów betonowych. Jedyny taki w Polsce z zachowaną bramą.  ',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a13.jpg'),
    thumbnail: require('./Obiekt_3001/a13_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Schron typu granit.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a14.jpg'),
    thumbnail: require('./Obiekt_3001/a14_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Schron typu T-7 z zachowaną bramą wejściową. Na terenie Polski jedynie w Muzeum Zimnej Wojny w Podborsku przetrwały bramy i wyposażenie tego typu schronów. W pozostałych bazach uległy one dewastacji. ',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a15.jpg'),
    thumbnail: require('./Obiekt_3001/a15_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Zachowana w doskonałym stanie wewnętrzna strona drzwi schronu T-7.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a16.jpg'),
    thumbnail: require('./Obiekt_3001/a16_thumb.jpg'),
    width: 1200,
    height: 783,
    description:
      'Wnętrze schronu T-7 w Podoborsku. Obecnie obiektem opiekuje się Muzeum Zimnej wojny w Podborsku, będące filią Muzeum Oręża Polskiego w Kołobrzegu.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a17.jpg'),
    thumbnail: require('./Obiekt_3001/a17_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Sala główna schronu T-7.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a18.jpg'),
    thumbnail: require('./Obiekt_3001/a18_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Wejścia do pomieszczeń technicznych.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a19.jpg'),
    thumbnail: require('./Obiekt_3001/a19_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Sala przystosowana do przechowywania głowic jądrowych. W podłodze widoczne otwory pozwalające na zakotwiczenie głowic przetrzymywanych na wózkach. ',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a20.jpg'),
    thumbnail: require('./Obiekt_3001/a20_thumb.jpg'),
    width: 797,
    height: 1200,
    description: 'Drzwi pancerne w części technicznej schronu.',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a21.jpg'),
    thumbnail: require('./Obiekt_3001/a21_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Szyby wentylacyjne schronu. ',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3001/a22.jpg'),
    thumbnail: require('./Obiekt_3001/a22_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Słupy, na których najprawdopodobniej rozwieszona była siatka maskująca. ',
    tags: ['Obiekt 3001'],
  },
  {
    original: require('./Obiekt_3002/b1.jpg'),
    thumbnail: require('./Obiekt_3002/b1_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Utwardzona droga prowadząca do obiektu 3002 w pobliżu Brzeźnicy-Kolonii.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b2.jpg'),
    thumbnail: require('./Obiekt_3002/b2_thumb.jpg'),
    width: 1200,
    height: 832,
    description:
      'Jedna z dróg wewnętrznych strefy koszarowej. Wzdłuż drogi posadzono świerki, mimo iż baza znajdowała się w lesie sosnowym. Odmienna barwa tych drzew była wyraźnie czytelna na zdjęciach lotniczych i zobrazowaniach satelitarnych. Błąd ten popełniono we wszystkich trzech bazach.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b3.jpg'),
    thumbnail: require('./Obiekt_3002/b3_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Parking wewnętrzny strefy garażowej.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b4.jpg'),
    thumbnail: require('./Obiekt_3002/b4_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Rowy strzeleckie wewnątrz ścisłej strefy.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b5.jpg'),
    thumbnail: require('./Obiekt_3002/b5_thumb.jpg'),
    width: 1200,
    height: 702,
    description:
      'Żelbetonowy bunkier osłaniający wejście do schronu T-7. Za bunkrem ceglana przybudówka. Obiekt był włączony do linii rowów strzeleckich.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b6.jpg'),
    thumbnail: require('./Obiekt_3002/b6_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Bunkier.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b7.jpg'),
    thumbnail: require('./Obiekt_3002/b7_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Schron na pojazd ciężarowy.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b8.jpg'),
    thumbnail: require('./Obiekt_3002/b8_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Pozostałości po ogrodzeniu ścisłej strefy.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b9.jpg'),
    thumbnail: require('./Obiekt_3002/b9_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Strzelnica.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b10.jpg'),
    thumbnail: require('./Obiekt_3002/b10_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Zbiornik wody. ',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b11.jpg'),
    thumbnail: require('./Obiekt_3002/b11_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Schron typu granit ze zdemontowanymi bramami. ',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b12.jpg'),
    thumbnail: require('./Obiekt_3002/b12_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Sklepienie schronu granit z enigmatycznym symbolem domalowanym po demontażu bram. ',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b13.jpg'),
    thumbnail: require('./Obiekt_3002/b13_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Schron Granit.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b14.jpg'),
    thumbnail: require('./Obiekt_3002/b14_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Widok z wnętrza schronu granit. Pod sufitem zawieszona była szyna, do której był zamocowany dźwig.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b15.jpg'),
    thumbnail: require('./Obiekt_3002/b15_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Widok z wnętrza schronu granit.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b16.jpg'),
    thumbnail: require('./Obiekt_3002/b16_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Pozostałości rampy załadunkowej przy schronie T-7.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b17.jpg'),
    thumbnail: require('./Obiekt_3002/b17_thumb.jpg'),
    width: 1200,
    height: 757,
    description: 'Zniszczone wnętrze schronu T-7 w Brzeźnicy-Kolonii.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3002/b18.jpg'),
    thumbnail: require('./Obiekt_3002/b18_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Pozostałości jednego z szybów wentylacyjnych.',
    tags: ['Obiekt 3002'],
  },
  {
    original: require('./Obiekt_3003/c1.jpg'),
    thumbnail: require('./Obiekt_3003/c1_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Pozostałości rowów strzeleckich znajdujących się w ścisłej strefie, które miały być wykorzystane podczas obrony bazy przed wrogim desantem.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c2.jpg'),
    thumbnail: require('./Obiekt_3003/c2_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Schrony na pojazdy ciężarowe wykopane w ścianach naturalnego, głębokiego jaru znajdującego się w ścisłej strefie bazy.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c3.jpg'),
    thumbnail: require('./Obiekt_3003/c3_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Pozostałości ukrycia na pojazd z deskowaniem zabezpieczającym ściany przed osuwaniem.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c4.jpg'),
    thumbnail: require('./Obiekt_3003/c4_thumb.jpg'),
    width: 1200,
    height: 888,
    description: 'Rowy strzeleckie umocnione drewnem. ',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c5.jpg'),
    thumbnail: require('./Obiekt_3003/c5_thumb.jpg'),
    width: 797,
    height: 1200,
    description: 'Rowy strzeleckie umocnione drewnem.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c6.jpg'),
    thumbnail: require('./Obiekt_3003/c6_thumb.jpg'),
    width: 1024,
    height: 680,
    description:
      'Pozostałości po dawnym ogrodzeniu bazy 3003 służą dziś jako płot chroniący młodniki.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c7.jpg'),
    thumbnail: require('./Obiekt_3003/c7_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Dawna strzelnica.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c8.jpg'),
    thumbnail: require('./Obiekt_3003/c8_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Zasypane wejście do schronu T-7.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c9.jpg'),
    thumbnail: require('./Obiekt_3003/c9_thumb.jpg'),
    width: 1200,
    height: 803,
    description: 'Wnętrze schronu T-7. Widok z przedsionka na główną salę.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c10.jpg'),
    thumbnail: require('./Obiekt_3003/c10_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Widok na główne pomieszczenie. Po przeciwnej stronie drugi przedsionek i wejście do schronu.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c11.jpg'),
    thumbnail: require('./Obiekt_3003/c11_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Wnętrze schronu. Po prawej stronie u góry pozostałości systemu wentylacyjnego i oświetlenia, po lewej miejsce gdzie znajdowały się radiatory systemu chłodzącego. Przez środek sufitu biegnie mocowanie szyny, do której podczepiony był dźwig. Po prawej stronie na dole wejścia do pomieszczeń, w których składowano głowice jądrowe.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c12.jpg'),
    thumbnail: require('./Obiekt_3003/c12_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Wnętrze schronu.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c13.jpg'),
    thumbnail: require('./Obiekt_3003/c13_thumb.jpg'),
    width: 1200,
    height: 797,
    description:
      'Wykonywanie pomiarów licznikiem Geigera-Müllera w pomieszczeniu, gdzie miała być przechowywana broń jądrowa. ',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c14.jpg'),
    thumbnail: require('./Obiekt_3003/c14_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Ślad pozostawiony przez zwiedzających schron.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c15.jpg'),
    thumbnail: require('./Obiekt_3003/c15_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Korytarz prowadzący do pomieszczeń technicznych. ',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c16.jpg'),
    thumbnail: require('./Obiekt_3003/c16_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Miejsce po rozebranym schronie typu granit.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c17.jpg'),
    thumbnail: require('./Obiekt_3003/c17_thumb.jpg'),
    width: 1200,
    height: 797,
    description: 'Miejsce po rozebranym schronie typu granit.',
    tags: ['Obiekt 3003'],
  },
  {
    original: require('./Obiekt_3003/c18.jpg'),
    thumbnail: require('./Obiekt_3003/c18_thumb.jpg'),
    width: 1024,
    height: 680,
    description: 'Miejsce, w którym przed laty stał budynek mieszkalny.',
    tags: ['Obiekt 3003'],
  },
];
