/* eslint-disable react/destructuring-assignment */

import { Button, Checkbox, Col, InputNumber, Row, Slider, Spin } from 'antd';
import { Page, PrismaZoom } from 'components';
import Fullscreen from 'react-full-screen';
import { Component, styled, React } from 'utils/create';
import { colors } from 'utils/variables';

const ImagesData = [
  {
    key: 'Podborsko_analiza_cieniowania_ALS',
    url: '/mocks/mapy/Podborsko_analiza_cieniowania_ALS.jpeg',
    name: 'Podborsko analiza cieniowania ALS',
  },
  {
    key: 'Podborsko_wektoryzacje_jpg',
    url: '/mocks/mapy/Podborsko_wektoryzacje_jpg.jpeg',
    name: 'Podborsko wektoryzacje jpg',
  },
  {
    key: 'Podborsko_zdjęcie_lotnicze_04_04_2005',
    url: '/mocks/mapy/Podborsko_zdjęcie_lotnicze_04_04_2005.jpeg',
    name: 'Podborsko zdjęcie lotnicze 04 04 2005',
  },
  {
    key: 'Podborsko_zdjęcie_lotnicze_17_06_1996',
    url: '/mocks/mapy/Podborsko_zdjęcie_lotnicze_17_06_1996.jpeg',
    name: 'Podborsko zdjęcie lotnicze 17 06 1996',
  },
  {
    key: 'Podborsko_zobrazowanie_satelitarne_z_programu_CORONA_28_07_1969',
    url: '/mocks/mapy/Podborsko_zobrazowanie_satelitarne_z_programu_CORONA_28_07_1969.jpeg',
    name: 'Podborsko zobrazowanie satelitarne z programu CORONA 28 07 1969',
  },
];

const PrismaZoomContainer = styled.div(({ fullScreen }) => ({
  position: 'relative',
  overflow: 'hidden',
  userSelect: 'none',

  ...(fullScreen
    ? {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      }
    : {
        width: '800px',
        height: '565px',
        margin: '1rem auto',
      }),
}));

const Controls = styled.div(({ fullScreen }) => ({
  ...(fullScreen
    ? {
        position: 'absolute',
        background: colors.white,
        zIndex: 100,
        right: 0,
        top: 0,
        padding: '1rem',
        opacity: 0.3,

        '&:hover': {
          opacity: 1,
        },
      }
    : {}),
}));

const Images = styled.div({
  position: 'relative',

  '& img': {
    margin: 0,
    width: '100%',

    '&:not(:first-child)': {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
});

const FullScreenButtons = styled(Button.Group)({
  width: '100%',
  textAlign: 'center',
});

class MapsPage extends Component {
  constructor(props) {
    super(props);
    const state = {
      fullScreen: false,
      expanded: true,
      images: {},
    };

    ImagesData.forEach(({ key }) => {
      state.images[key] = {
        checked: true,
        loaded: false,
        opacity: 100,
      };
    });

    this.state = state;
  }

  onFullScreen = fullScreen => {
    this.setState({ fullScreen, expanded: true });
  };

  toggleControls = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  onLayerToggle = (e, key) => {
    const { images } = this.state;
    images[key].checked = e.target.checked;
    this.setState({ images });
  };

  onOpacityChange = (opacity, key) => {
    const { images } = this.state;
    images[key].opacity = opacity;
    this.setState({ images });
  };

  onImageLoad = key => {
    const { images } = this.state;
    images[key].loaded = true;
    this.setState({ images });
  };

  render() {
    const { fullScreen, expanded, images } = this.state;
    const spinning = Object.values(images).some(({ loaded }) => !loaded);

    return (
      <Page>
        <h1>Mapy</h1>

        <Spin spinning={spinning}>
          <Fullscreen enabled={fullScreen} onChange={this.onFullScreen}>
            <Controls fullScreen={fullScreen}>
              {expanded &&
                ImagesData.map(({ key, name }) => {
                  const { checked, opacity } = this.state.images[key];

                  return (
                    <Row key={key}>
                      <Col span={fullScreen ? 16 : 8} offset={fullScreen ? 0 : 5}>
                        <Checkbox onChange={e => this.onLayerToggle(e, key)} checked={checked}>
                          <strong>{name}</strong>
                        </Checkbox>
                      </Col>
                      <Col span={4}>
                        <Slider
                          min={0}
                          max={100}
                          value={opacity}
                          onChange={value => this.onOpacityChange(value, key)}
                          disabled={!checked}
                        />
                      </Col>
                      <Col span={2}>
                        <InputNumber
                          min={0}
                          max={100}
                          style={{ marginLeft: 16 }}
                          value={opacity}
                          onChange={value => this.onOpacityChange(value, key)}
                          disabled={!checked}
                        />
                      </Col>
                    </Row>
                  );
                })}
              {fullScreen && (
                <FullScreenButtons>
                  <Button
                    icon={expanded ? 'upload' : 'download'}
                    type="primary"
                    htmlType="button"
                    onClick={this.toggleControls}
                  >
                    {expanded ? 'Zwiń' : 'Rozwiń'}
                  </Button>
                  <Button
                    htmlType="button"
                    icon="fullscreen-exit"
                    onClick={() => this.onFullScreen(false)}
                  >
                    Zamknij
                  </Button>
                </FullScreenButtons>
              )}
            </Controls>
            <PrismaZoomContainer fullScreen={fullScreen}>
              <PrismaZoom animDuration={0} decelerationDuration={0}>
                <Images>
                  {ImagesData.filter(({ key }) => this.state.images[key].checked)
                    .reverse()
                    .map(({ key, url, name }) => (
                      <img
                        key={key}
                        src={url}
                        style={{ opacity: this.state.images[key].opacity / 100 }}
                        alt={name}
                        onLoad={() => this.onImageLoad(key)}
                      />
                    ))}
                </Images>
              </PrismaZoom>
            </PrismaZoomContainer>
          </Fullscreen>
          <Button
            htmlType="button"
            icon="fullscreen"
            onClick={() => this.onFullScreen(true)}
            style={{ display: 'block', margin: '0 auto' }}
          >
            Uruchom w pełnym ekranie
          </Button>
        </Spin>
      </Page>
    );
  }
}

export default MapsPage;
